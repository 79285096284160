import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./App.css";
import axios from "axios";
import { FaWandMagicSparkles, FaSpinner } from "react-icons/fa6";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
function App() {
  const [courseName, setCourseName] = useState("");
  const [clientName, setClientName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [courseOutline, setCourseOutline] = useState("");
  const [isLoadingProposal, setIsLoadingProposal] = useState(false);
  const [quillChange, setQuillChange] = useState(false);

  const modules = {
    toolbar: [["bold"], [{ list: "ordered" }, { list: "bullet" }]],
  };

  const handleGenerate = async (field, inputText, limit) => {
    try {
      const response = await axios.get(
        `https://kacs-live.demotrt.com/api/v1/course_generate_details`,
        {
          params: { prompt: inputText, limit: limit },
        }
      );
      const sendingText = response.data.courseObjectives
        .replace(/##/g, "")
        .replace(/#/g, "");
      // Update the respective field based on the API response
      switch (field) {
        case "courseObjective":
          return sendingText;
        case "courseOverview":
          return sendingText;
        case "courseBenefits":
          return sendingText;
        case "courseOutline1":
          return sendingText; // Keep original input
        case "courseOutline2":
          return sendingText;
        default:
          break;
      }
    } catch (error) {
      console.error("Error with the API request:", error);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingProposal(true);
    try {
      let outline_flag_handler = "false";
      const staticTextObjective = `course name: ${courseName} provide me 7 course objective with title and explanation format should not have explanation word , like example **1. Introduction to JavaScript Basics**: Understand the fundamental concepts of JavaScript, including variables, data types, and operators.`;
      const staticTextOverview = `course name: ${courseName} provide me 2 to 3 course overview in paragraph,do not include tittle in paragraph`;
      const staticTextBenefits = `course name: ${courseName} provide me 7 course benefits, There will be space after every point`;
      const staticTextOutline1 = `course name: ${courseName} provide me 4 course outline module wise. Do not include course outline word. For lists, use only circled bullet points (e.g , ◦), bullet points looked like this ◦.like this **Module 1: Introduction to Java**  
      ◦ Overview of Java and its features  
      ◦ Setting up the Java development environment  
      ◦ Understanding Java syntax and structure  
      ◦ Writing your first Java program       
      ◦ Basic data types and variables `;
      const staticTextOutline2 = `course name: ${courseName} provide me 5 and 6 and 7 course outline module wise. For lists, use only circled bullet points (e.g , ◦), bullet points looked like this ◦.Do not include course outline word . like this **Module 1: Introduction to Java**  
      ◦ Overview of Java and its features  
      ◦ Setting up the Java development environment  
      ◦ Understanding Java syntax and structure  
      ◦ Writing your first Java program       
      ◦ Basic data types and variables`;

      const data1 = await handleGenerate(
        "courseObjective",
        staticTextObjective,
        200
      );
      const data2 = await handleGenerate(
        "courseOverview",
        staticTextOverview,
        330
      );
      const data3 = await handleGenerate(
        "courseBenefits",
        staticTextBenefits,
        180
      );
      let staticCourseOutLine,
        staticCourseOutLine2 = "";

      if (quillChange) {
        staticCourseOutLine = courseOutline;
        outline_flag_handler = "true"; // User has typed something meaningful
      } else {
        staticCourseOutLine = await handleGenerate(
          "courseOutline1",
          staticTextOutline1,
          164
        );
        staticCourseOutLine2 = await handleGenerate(
          "courseOutline2",
          staticTextOutline2,
          164
        );
        outline_flag_handler = "false"; // User has not typed or removed all content
      }

      console.log("check this  ::: ", staticCourseOutLine);

      const response = await axios({
        url: "https://kacs-live.demotrt.com/api/v1/generate_ppt",
        method: "POST",
        data: {
          course_name: courseName,
          course_date: startDate ? moment(startDate).format("DD-MM-YYYY") : "",
          client_company_name: clientName,
          course_objective: data1, // Send original
          course_overview: data2, // Send original
          course_benefits: data3,
          course_outline: staticCourseOutLine,
          course_outline2: staticCourseOutLine2,

          course_title: courseName,
          outline_flag: outline_flag_handler,
        },
        responseType: "blob", // This is critical to handle binary data
      });
      // Create a Blob from the response data and generate a download link
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "outputDemo.pptx"); // Filename for the download
      document.body.appendChild(link);
      link.click(); // Programmatically click the download link
      link.remove();
    } catch (error) {
      console.error("Error with the API request:", error);
    } finally {
      setIsLoadingProposal(false);
    }
  };
  const handleCancel = () => {};
  const handleChange = (content) => {
    const strippedContent = content.replace(/(<([^>]+)>)/gi, "").trim(); // Removes HTML tags and trims the content
    console.log("content ::: ", content);
    setCourseOutline(content);
    if (!strippedContent) {
      console.log("Editor is empty");
      setQuillChange(false);
    } else {
      console.log("Editor has content");
      setQuillChange(true);
    }
  };
  return (
    <div className="main">
      {/* Left */}
      <div className="sidebar">
        <div className="sidebar-chip">
          <div className="sidebar-content">Prompt</div>
        </div>
      </div>
      {/* Right */}
      <div className="contnet-wrapper">
        <div className="inner-contnet-wrapper">
          <div className="">
            <p className="heading">Enter your Prompt</p>
          </div>
          <form onSubmit={handleSubmit} className="course-form">
            <div className="input_box">
              <label className="label_name">Course Name:</label>
              <input
                type="text"
                className="input_field"
                value={courseName}
                onChange={(e) => setCourseName(e.target.value)}
                placeholder="Enter course name"
              />
            </div>
            <div className="input_box">
              <label className="label_name">Client/Company Name:</label>
              <input
                type="text"
                className="input_field"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
                placeholder="Enter Client/Company name"
              />
            </div>
            <div className="input_box">
              <label className="label_name">Course Outline:</label>
              <ReactQuill
                value={courseOutline}
                onChange={handleChange}
                modules={modules}
                className="rich_text_editor"
              />
            </div>
            <div className="input_box">
              <label className="label_name">Select Date:</label>
              <div className="custom-datepicker">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  placeholderText="Select a date"
                  className="input_field "
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
            <div className="btn-wrapper">
              <button
                type="submit"
                disabled={isLoadingProposal}
                className="primary_btn"
              >
                {isLoadingProposal ? (
                  <FaSpinner className="spinner-icon" />
                ) : (
                  "Generate Proposal"
                )}
              </button>
              <button
                type="button"
                onClick={handleCancel}
                className="primary_btn"
              >
                Clear
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default App;
